<template>
  <div class="inputBox">
    <baseSelectDadata v-model="value" :label="item.label" :type="refs" :placeholder="item.placeholder"
      :errorMsg="errorMsg" @dadata="dadataToStorage($event)" @blur="eventBlur"
      @focus="eventFocus" />
  </div>
</template>

<script>
import DataForInput from '@/store/repeatLoan/longFormOptions';
import { mapState } from 'vuex';
import tracing from '@/common/tracing';

export default {
  name: 'formSelectDadata',
  components: {
    baseSelectDadata: () => import('@/components/baseSelectDadata')
  },
  props: {
    refs: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      item: DataForInput[this.refs]
    };
  },

  computed: {
    ...mapState({
      statusVuex: (state) => state.repeatLoan.workData,
      valueVuex: (state) => state.repeatLoan.sendData
    }),
    errorMsg() {
      return this.statusVuex[this.refs].ShowError ? this.statusVuex[this.refs].msg : '';
    },
    value: {
      get() {
        return this.valueVuex[this.refs];
      },
      set(value) {
        this.$store.dispatch('repeatLoan/saveSendData', {
          target: this.refs,
          value: value
        });
      }
    }
  },

  methods: {
    eventBlur() {
      this.$store.dispatch('repeatLoan/errorShow', {
        target: this.refs,
        value: true
      });
      this.$emit('blur', this.valueVuex[this.refs]);
      setTimeout(() => {
        this.suggestions = [];
      }, 300);
    },
    eventFocus() {
      this.$store.dispatch('repeatLoan/errorShow', {
        target: this.refs,
        value: false
      });
      tracing.traceInput({
        action: 164,
        refs: this.refs,
        value: this.valueVuex[this.refs]
      });
    },
    dadataToStorage(item) {
      if (this.refs.indexOf('_ADDRESS') > 0) {
        this.$store.dispatch('repeatLoan/saveSendData', {
          target: this.refs + '_DADATA',
          value: item
        })
        return
      }
      if (this.refs.indexOf('NAME') > 0) {
        if (item?.data?.gender === 'MALE') {
          this.$store.dispatch('repeatLoan/saveSendData', {
            target: 'GENDER',
            value: 'M'
          })
        } else if (item?.data?.gender === 'FEMALE') {
          this.$store.dispatch('repeatLoan/saveSendData', {
            target: 'GENDER',
            value: 'F'
          })
        }
        return
      }
      if (this.refs.indexOf('WORK_JOB') >= 0) {
        this.$store.dispatch('repeatLoan/saveSendData', {
          target: 'WORK_JOB_INN',
          value: item.data.inn
        })
        if (item.data.phones !== null) {
          this.$store.dispatch('repeatLoan/saveSendData', {
            target: 'WORK_JOB_PHONE',
            value: item.data.phones[0]
          })
        }
        return
      }
    }
  }
};
</script>

<style scoped>
.active { top: -3vw }
.suggestions { position: absolute; left: 0; top: 10vw; width: 100%; z-index: 100; background: #ffffff; border: 1px solid #EEE; margin: 0;  padding: 0 }
.items { border-bottom: 1px solid #eee; padding: 1vw }

@media (min-width: 760px) {
  .suggestions { top: 3vw }
  .items { padding: 0.5vw }
}

@media (min-width: 1240px) {
  .active { top: -10px }
  .suggestions { top: 36px; }
  .items { padding: 5px }
  .items:hover, .hover { background: #eee; cursor: pointer; }
}
</style>
